myApp.controller('employeesController', ['$scope', '$cookies', 'employeeModel', '$location','$timeout', 'ngDialog', function($scope, $cookies, employeeModel, $location,$timeout, ngDialog) {
    /*get all employee for first time*/




    if ($scope.global.empSearchTerm == undefined) {
        $scope.searchTerm = '';
    }
    else {
        $scope.searchTerm = $scope.global.empSearchTerm;
    }




    /* $scope variable*/
    angular.extend($scope, {
        
        searchFocused: false,

        chngEmp: {},

        lastPage: 0,
        currentPage: 1,
        range: [],
        totalEmployee: 0


    });
    /* $scope functions*/
    angular.extend($scope, {

        getData: function(pageNumber) {

            employeeModel.getEmployee(pageNumber).success(function(response) {


                $scope.lastPage = response.last_page;
                $scope.totalEmployee = response.total;
                $scope.currentPage = response.current_page;

                // Pagination Range
                var pages = [];

                for (var i = 1; i <= response.last_page; i++) {
                    pages.push(i);
                }

                $scope.range = pages;


                $scope.allEmployee = response.data;
                $scope.global.allEmployee = response.data;

                $scope.global.totalEmployee = response.total;
                $scope.global.employeeCurrentPage = response.current_page;
                $scope.global.employeeRange = pages;
                $scope.global.employeeLastPage = response.last_page;


            });


        },


        change: function() {

            employeeModel.changeRole($scope.chngEmp).success(function(res) {
                // $scope.allEmployee = res;
                
                var index = $scope.allEmployee.indexOf($scope.chngEmp)
                $scope.allEmployee[index].role=2;

                

                ngDialog.close();
                ngDialog.open({
                    template: '\
                                <p>Changed...</p>\
                                <div class="ngdialog-buttons">\
                                <button type="button" class="ngdialog-button btn btn-primary" ng-click="closeThisDialog()">OK</button>\
                                 </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });
            });
        },
        /* change role of employee */
        changeRole: function(emp) {
            $scope.chngEmp = emp;
            ngDialog.openConfirm({
                template: '\
                             <p>Are you sure you want to change the role?</p>\
                            <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog()">No</button>\
                               <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="change()">Yes</button>\
                            </div>',

                plain: true,
                scope: $scope
            });


        },
        /*confirm if change role of employee */
        delete: function() {

            employeeModel.deleteEmployee($scope.delEmp).success(function(res) {



                var index = $scope.allEmployee.indexOf($scope.delEmp)
                $scope.allEmployee.splice(index, 1);




                ngDialog.close();
                ngDialog.open({

                    template: '\
                                              <p>Deleted...</p>\
                                              <div class="ngdialog-buttons">\
                                                  <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                                              </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

                --$scope.totalEmployee;
            });
        },
        /*delete employee */
        deleteEmployee: function(emp) {
            $scope.delEmp = emp;
            ngDialog.openConfirm({
                template: '\
                            <p>Are you sure you want to delete ?</p>\
                            <div class="ngdialog-buttons">\
                                <button type="button" class="ngdialog-button btn " ng-click="closeThisDialog()">No</button>\
                                <button type="button" class="ngdialog-button btn btn-danger" ng-click="delete()">Yes</button>\
                            </div>',

                plain: true,
                scope: $scope
            });

        }, /*confirm if delete employee */
        
         getDatas: function(pageNumber) {

            $scope.totalEmployee = 0;

            if ($scope.searchTerm == '') {

               
                    $scope.getData(pageNumber);
              

            }else {


               
                    employeeModel.searchEmployee($scope.searchTerm, pageNumber).success(function(response) {

                       
                $scope.lastPage = response.last_page;
                $scope.totalEmployee = response.total;
                $scope.currentPage = response.current_page;

                // Pagination Range
                var pages = [];

                for (var i = 1; i <= response.last_page; i++) {
                    pages.push(i);
                }

                $scope.range = pages;


                $scope.allEmployee = response.data;
                $scope.global.allEmployee = response.data;

                $scope.global.totalEmployee = response.total;
                $scope.global.employeeCurrentPage = response.current_page;
                $scope.global.employeeRange = pages;
                $scope.global.employeeLastPage = response.last_page;



                    });

              
            }





        },
    });

    if ($scope.global.allEmployee == undefined) {
        $scope.getDatas(1);
    }
    else {
        $scope.allInvoice = $scope.global.allInvoice;



        $scope.allEmployee = $scope.global.allEmployee;
        $scope.currentPage = $scope.global.employeeCurrentPage;
        $scope.range = $scope.global.employeeRange;

        $scope.lastPage = $scope.global.employeeLastPage;

    }
    
      var searchTermTimeout, tempSearchTerm;

    $scope.$watch('searchTerm', function(val) {

        $scope.global.empSearchTerm = val;


        if (!$scope.searchFocused) {
            return;
        }

        if (searchTermTimeout) $timeout.cancel(searchTermTimeout);

        tempSearchTerm = val;

        searchTermTimeout = $timeout(function() {


            $scope.getDatas(1);



        }, 1000); // delay 250 ms


    })


}]);
