myApp.controller('coversheetController', ['$scope', '$cookies', 'coversheetModel', '$location', '$timeout', 'ngDialog', '$filter', 'moment', function($scope, $cookies, coversheetModel, $location, $timeout, ngDialog, $filter, moment) {


    if ($scope.global.csHome == undefined) {
        $scope.home = true;
    }
    else {
        $scope.home = $scope.global.csHome;
    }

    if ($scope.global.csSearchTerm == undefined) {
        $scope.searchTerm = '';
    }
    else {
        $scope.searchTerm = $scope.global.csSearchTerm;
    }


    /*variable*/
    angular.extend($scope, {




        searchFocused: false,
        readonly: true,
        errorDiv: false,
        errorMessage: [],
        detailCoversheet: {},
        editCoversheet: {},
        newCoversheet: {},
        // allCoversheet: [],
        modelInsert: false,
        modelDetail: false,
        modelEdit: false,
        isSaving: false,

        lastPage: 0,
        currentPage: 1,
        range: [],
        totalCoversheet: 0




    });

    /*function*/
    angular.extend($scope, {


        getData: function(pageNumber) {

            coversheetModel.getCoversheet(pageNumber).success(function(response) {


                $scope.lastPage = response.last_page;
                $scope.totalCoversheet = response.total;
                $scope.currentPage = response.current_page;

                // Pagination Range
                var pages = [];

                for (var i = 1; i <= response.last_page; i++) {
                    pages.push(i);
                }

                $scope.range = pages;


                $scope.allCoversheet = response.data;



                $scope.global.allCoversheet = response.data;
                $scope.global.totalCoversheet = response.total;
                $scope.global.coversheetCurrentPage = response.current_page;
                $scope.global.coversheetRange = pages;
                $scope.global.coversheetLastPage = response.last_page;



            });


        },

        viewHome: function() {
            $scope.searchTerm = '';
            $scope.home = true;
            $scope.global.csHome = true;

            $scope.getDatas(1);

        },
        getTrashData: function(pageNumber) {
            coversheetModel.getCoversheetTrash(pageNumber).success(function(response) {


                $scope.lastPage = response.last_page;
                $scope.totalCoversheet = response.total;
                $scope.currentPage = response.current_page;

                // Pagination Range
                var pages = [];

                for (var i = 1; i <= response.last_page; i++) {
                    pages.push(i);
                }

                $scope.range = pages;


                $scope.allCoversheet = response.data;



                $scope.global.allCoversheet = response.data;
                $scope.global.totalCoversheet = response.total;
                $scope.global.coversheetCurrentPage = response.current_page;
                $scope.global.coversheetRange = pages;
                $scope.global.coversheetLastPage = response.last_page;



            });

        },

        viewTrash: function() {
            $scope.searchTerm = '';
            $scope.home = false;
            $scope.global.csHome = false;
            $scope.getTrashData(1);

        },




        msToTime: function(duration) {
            var milliseconds = parseInt((duration % 1000) / 100),
                seconds = parseInt((duration / 1000) % 60),
                minutes = parseInt((duration / (1000 * 60)) % 60),
                hours = parseInt((duration / (1000 * 60 * 60)) % 24);

            hours = (hours < 10) ? "0" + hours : hours;
            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + seconds : seconds;

            return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
        },
        timeToString: function(time) {
            var k = time.split(':');
            return {
                hours: k[0],
                minutes: k[1],
                seconds: k[2]
            };
        },

        currentDate: function() {
            var j = new Date();
            $scope.newCoversheet.date = j;
            $scope.newCoversheet.time = j;
        },

        /*model insert show/hide*/
        modelInsertShow: function() {
            $scope.modelInsert = true;


        },
        modelInsertClose: function() {
            $scope.isSaving = false;
            $scope.modelInsert = false;
            $scope.newCoversheet = {};
        },

        /*model detail show/hide*/
        modelDetailShow: function(cs) {
            $scope.detailCoversheet = cs;
            console.log(cs);
            $scope.modelDetail = true;
        },
        modelDetailClose: function() {
            $scope.modelDetail = false;
        },

        /*model edit show/hide*/
        modelEditShow: function(cs) {
            $scope.edt = cs;
            var csData = angular.copy(cs);


            if (csData.arrived !== null) {
                csData.arrived = new Date(csData.arrived);
            }
            
            csData.dateOfSurvey = new Date(csData.dateOfSurvey);

            $scope.editCoversheet = csData;


            $scope.modelEdit = true;
        },
        modelEditClose: function() {
            $scope.modelEdit = false;

        },

        /*insert new coversheet*/
        insertCoversheet: function(coversheetForm) {



            $scope.isSaving = true;

            if (coversheetForm.$valid) {
                $scope.coversheetFormSubmitted = false;

                coversheetModel.insertCoversheet($scope.newCoversheet)
                    .success(function(response) {
           
                        $scope.newCoversheet = {};
                        $scope.isSaving = false;
                        $scope.allCoversheet.push(response);

                        ngDialog.open({
                            template: '\
                            <p>Inserted successfully...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                            plain: true,
                            className: 'ngdialog-theme-default'
                        });
                        ++$scope.totalCoversheet;


                    })
                    .error(function(err) {


                        $scope.isSaving = false;
                        console.log(err);
                        ngDialog.open({
                            template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                            plain: true,
                            className: 'ngdialog-theme-default'
                        });



                        $location.path('/');
                    })

            }
            else {
                $scope.coversheetFormSubmitted = true;
                console.log('invalid form');
                $scope.isSaving = false;
            }

        },

        /*edit coversheet*/
        EditCoversheet: function(coversheetEditForm) {



            $scope.editIsSaving = true;

            if (coversheetEditForm.$valid) {
                $scope.coversheetEditFormSubmitted = false;
                coversheetModel.editCoversheet($scope.editCoversheet)
                    .success(function(response) {

                        console.log(response);
                        console.log($scope.editCoversheet);
                        var index = $scope.allCoversheet.indexOf($scope.edt)

                        $scope.allCoversheet[index] = $scope.editCoversheet;


                        $scope.editIsSaving = false;




                        ngDialog.open({
                            template: '\
                            <p>Edited successfully...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                            plain: true,
                            className: 'ngdialog-theme-default'
                        });
                        $scope.modelEditClose();
                        $scope.editCoversheet = {};

                    })
                    .error(function(err) {

                        $scope.editIsSaving = false;
                        console.log(err);
                        ngDialog.open({
                            template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                            plain: true,
                            className: 'ngdialog-theme-default'
                        });
                    })

            }
            else {
                $scope.coversheetEditFormSubmitted = true;
                console.log('invalid form');
                $scope.editIsSaving = false;
            }

        },

        /*delete coversheet */
        delete: function() {


            coversheetModel.deleteCoversheet($scope.delCs.id).
            success(function(res) {
                

                var index = $scope.allCoversheet.indexOf($scope.delCs)
                $scope.allCoversheet.splice(index, 1);


                ngDialog.close();
                ngDialog.open({
                    template: '\
                            <p>Deleted...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

                --$scope.totalCoversheet;

            }).
            error(function() {

                ngDialog.open({
                    template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

            });
        },

        deleteCoversheet: function(cs) {


            $scope.delCs = cs;
            ngDialog.openConfirm({
                template: '\
                                    <p>Are you sure you want to delete ?</p>\
                                    <div class="ngdialog-buttons">\
                                        <button type="button" class="ngdialog-button btn " ng-click="closeThisDialog()">No</button>\
                                        <button type="button" class="ngdialog-button btn btn-danger" ng-click="delete()">Yes</button>\
                                    </div>',

                plain: true,
                scope: $scope
            });

        },
        /*confirm if delete coversheet */


        /*restore coversheet */
        restore: function() {


            coversheetModel.restoreCoversheet($scope.restoreCs.id).
            success(function(res) {

                var index = $scope.allCoversheet.indexOf($scope.restoreCs)
                $scope.allCoversheet.splice(index, 1);


                ngDialog.close();
                ngDialog.open({
                    template: '\
                            <p>Restored...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

                --$scope.totalCoversheet;

            }).
            error(function() {

                ngDialog.open({
                    template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

            });
        },

        restoreCoversheet: function(cs) {


            $scope.restoreCs = cs;
            ngDialog.openConfirm({
                template: '\
                                    <p>Are you sure you want to restore ?</p>\
                                    <div class="ngdialog-buttons">\
                                        <button type="button" class="ngdialog-button btn " ng-click="closeThisDialog()">No</button>\
                                        <button type="button" class="ngdialog-button btn btn-danger" ng-click="restore()">Yes</button>\
                                    </div>',

                plain: true,
                scope: $scope
            });

        },
        /*confirm if restore coversheet */



        getDatas: function(pageNumber) {

            $scope.totalCoversheet = 0;

            if ($scope.searchTerm == '') {

                if ($scope.home) {
                    $scope.getData(pageNumber);
                }
                else {
                    $scope.getTrashData(pageNumber);
                }

            }




            if ($scope.searchTerm != '') {


                if ($scope.home) {
                    coversheetModel.searchCoversheet($scope.searchTerm, pageNumber).success(function(response) {

                        $scope.lastPage = response.last_page;
                        $scope.totalCoversheet = response.total;
                        $scope.currentPage = response.current_page;

                        // Pagination Range
                        var pages = [];

                        for (var i = 1; i <= response.last_page; i++) {
                            pages.push(i);
                        }

                        $scope.range = pages;


                        $scope.allCoversheet = response.data;



                        $scope.global.allCoversheet = response.data;
                        $scope.global.totalCoversheet = response.total;
                        $scope.global.coversheetCurrentPage = response.current_page;
                        $scope.global.coversheetRange = pages;
                        $scope.global.coversheetLastPage = response.last_page;


                    });

                }
                else {
                    coversheetModel.searchCoversheetTrash($scope.searchTerm, pageNumber).success(function(response) {

                        $scope.lastPage = response.last_page;
                        $scope.totalCoversheet = response.total;
                        $scope.currentPage = response.current_page;

                        // Pagination Range
                        var pages = [];

                        for (var i = 1; i <= response.last_page; i++) {
                            pages.push(i);
                        }

                        $scope.range = pages;


                        $scope.allCoversheet = response.data;



                        $scope.global.allCoversheet = response.data;
                        $scope.global.totalCoversheet = response.total;
                        $scope.global.coversheetCurrentPage = response.current_page;
                        $scope.global.coversheetRange = pages;
                        $scope.global.coversheetLastPage = response.last_page;


                    });

                }
            }





        },





        printCoversheet: function() {
            var printContents = document.getElementById('coversheetDetail').innerHTML;
            var popupWin = window.open('_blank', 'width=700,height=700');
            popupWin.document.open();
            popupWin.document.write('<html><head><title>coversheet:' + $scope.detailCoversheet.id + '</title><link rel="stylesheet" type="text/css" href="/bower_components/bootstrap/paper/paper.css" /><link rel="stylesheet" type="text/css" href="/css/app.css"  /></head><body onload="window.print()">' + printContents + '</body></html>');
            popupWin.document.close();
        }
    });




    if ($scope.global.allCoversheet == undefined) {
        $scope.getDatas(1);
    }
    else {
        $scope.allCoversheet = $scope.global.allCoversheet;



        $scope.totalCoversheet = $scope.global.totalCoversheet;
        $scope.currentPage = $scope.global.coversheetCurrentPage;
        $scope.range = $scope.global.coversheetRange;

        $scope.lastPage = $scope.global.coversheetLastPage;

    }

    var searchTermTimeout, tempSearchTerm;

    $scope.$watch('searchTerm', function(val) {

        $scope.global.csSearchTerm = val;


        if (!$scope.searchFocused) {
            return;
        }

        if (searchTermTimeout) $timeout.cancel(searchTermTimeout);

        tempSearchTerm = val;

        searchTermTimeout = $timeout(function() {


            $scope.getDatas(1);



        }, 1000); // delay 250 ms


    })



}]);
