myApp.controller('filenoController', ['$scope', 'filenoModel', '$location', '$timeout','ngDialog', function($scope, filenoModel, $location,$timeout, ngDialog) {


    if ($scope.global.fnHome == undefined) {
        $scope.home = true;
    }
    else {
        $scope.home = $scope.global.fnHome;
    }

    if ($scope.global.fnSearchTerm == undefined) {
        $scope.searchTerm = '';
    }
    else {
        $scope.searchTerm = $scope.global.fnSearchTerm;
    }



    /*variable*/
    angular.extend($scope, {
        searchFocused: false,

        readonly: true,
        errorDiv: false,
        errorMessage: [],
        detailFileno: {},
        editFileno: {},
        newFileno: {},

        modelInsert: false,
        modelDetail: false,
        modelEdit: false,
        isSaving: false,

        lastPage: 0,
        currentPage: 1,
        range: [],
        totalFileno: 0


    });

    /*function*/
    angular.extend($scope, {

        getData: function(pageNumber) {



            filenoModel.getFileno(pageNumber).success(function(response) {


                $scope.lastPage = response.last_page;
                $scope.totalFileno = response.total;
                $scope.currentPage = response.current_page;

                // Pagination Range
                var pages = [];

                for (var i = 1; i <= response.last_page; i++) {
                    pages.push(i);
                }

                $scope.range = pages;


                $scope.allFileno = response.data;



                $scope.global.allFileno = response.data;
                $scope.global.totalFileno = response.total;
                $scope.global.filenoCurrentPage = response.current_page;
                $scope.global.filenoRange = pages;
                $scope.global.filenoLastPage = response.last_page;


            });


        },


        viewHome: function() {
            $scope.searchTerm = '';
            $scope.home = true;
            $scope.global.fnHome = true;

            $scope.getDatas(1);

        },

        getTrashData: function(pageNumber) {
            filenoModel.getFilenoTrash(pageNumber).success(function(response) {

                $scope.lastPage = response.last_page;
                $scope.totalFileno = response.total;
                $scope.currentPage = response.current_page;

                // Pagination Range
                var pages = [];

                for (var i = 1; i <= response.last_page; i++) {
                    pages.push(i);
                }

                $scope.range = pages;


                $scope.allFileno = response.data;



                $scope.global.allFileno = response.data;
                $scope.global.totalFileno = response.total;
                $scope.global.filenoCurrentPage = response.current_page;
                $scope.global.filenoRange = pages;
                $scope.global.filenoLastPage = response.last_page;



            });

        },

        viewTrash: function() {
            $scope.searchTerm = '';
            $scope.home = false;
            $scope.global.fnHome = false;
            $scope.getTrashData(1);

        },




        msToTime: function(duration) {
            var milliseconds = parseInt((duration % 1000) / 100),
                seconds = parseInt((duration / 1000) % 60),
                minutes = parseInt((duration / (1000 * 60)) % 60),
                hours = parseInt((duration / (1000 * 60 * 60)) % 24);

            hours = (hours < 10) ? "0" + hours : hours;
            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + seconds : seconds;

            return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
        },
        timeToString: function(time) {
            var k = time.split(':');
            return {
                hours: k[0],
                minutes: k[1],
                seconds: k[2]
            };
        },


        /*model insert show/hide*/
        modelInsertShow: function() {
            $scope.modelInsert = true;


        },
        modelInsertClose: function() {
            $scope.isSaving = false;
            $scope.modelInsert = false;
            $scope.newFileno = {};
        },

        /*model detail show/hide*/
        modelDetailShow: function(fn) {
            $scope.detailFileno = fn;
            console.log(fn);
            $scope.modelDetail = true;
        },
        modelDetailClose: function() {
            $scope.modelDetail = false;
        },

        /*model edit show/hide*/
        modelEditShow: function(fn) {
            $scope.edt = fn;

            var fnData = angular.copy(fn);
            $scope.editFileno = fnData;
            $scope.modelEdit = true;
        },
        modelEditClose: function() {
            $scope.modelEdit = false;

        },


        /*insert new fileno*/
        insertFileno: function(filenoForm) {


            $scope.isSaving = true;

            if (filenoForm.$valid) {
                $scope.finenoFormSubmitted = false;

                filenoModel.insertFileno($scope.newFileno).
                success(function(response) {

                    $scope.newFileno = {};
                    $scope.isSaving = false;
                    $scope.allFileno.push(response);

                    ngDialog.open({
                        template: '\
                            <p>Inserted successfully...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                        plain: true,
                        className: 'ngdialog-theme-default'
                    });

                    ++$scope.totalFileno;


                }).
                error(function(err) {

                    $scope.isSaving = false;
                    console.log(err);
                    ngDialog.open({
                        template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                        plain: true,
                        className: 'ngdialog-theme-default'
                    });


                    $location.path('/');

                })

            }
            else {
                $scope.filenoFormSubmitted = true;
                console.log('invalid form');
                $scope.isSaving = false;
            }

        },

        /*edit fileno*/
        EditFileno: function(filenoEditForm) {



            $scope.editIsSaving = true;

            if (filenoEditForm.$valid) {

                $scope.filenoEditFormSubmitted = false;

                filenoModel.editFileno($scope.editFileno)
                    .success(function(response) {

                        $scope.editIsSaving = false;


                        console.log(response);
                        console.log($scope.editFileno);
                        var index = $scope.allFileno.indexOf($scope.edt)

                        $scope.allFileno[index] = $scope.editFileno;




                        ngDialog.open({
                            template: '\
                            <p>Edited successfully...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                            plain: true,
                            className: 'ngdialog-theme-default'
                        });
                        $scope.modelEditClose();
                        $scope.editFileno = {};





                    })
                    .error(function(err) {
                        $scope.editIsSaving = false;
                        console.log(err);
                        ngDialog.open({
                            template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                            plain: true,
                            className: 'ngdialog-theme-default'
                        });
                    })

            }
            else {
                $scope.filenoEditFormSubmitted = true;
                console.log('invalid form');
                $scope.editIsSaving = false;
            }

        },

        /*delete Fileno */
        delete: function() {




            filenoModel.deleteFileno($scope.delFn.id).
            success(function(res) {


                var index = $scope.allFileno.indexOf($scope.delFn)
                $scope.allFileno.splice(index, 1);


                ngDialog.close();
                ngDialog.open({
                    template: '\
                            <p>Deleted...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });
                --$scope.totalFileno;

            }).
            error(function() {

                ngDialog.open({
                    template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

            });
        },
        deleteFileno: function(fn) {
            console.log(fn);

            $scope.delFn = fn;
            ngDialog.openConfirm({
                template: '\
                                    <p>Are you sure you want to delete ?</p>\
                                    <div class="ngdialog-buttons">\
                                        <button type="button" class="ngdialog-button btn " ng-click="closeThisDialog()">No</button>\
                                        <button type="button" class="ngdialog-button btn btn-danger" ng-click="delete()">Yes</button>\
                                    </div>',

                plain: true,
                scope: $scope
            });

        },
        /*confirm if delete fileno */
        
        
            /*restore fileno */
        restore: function() {


            filenoModel.restoreFileno($scope.restoreFn.id).
            success(function(res) {

                var index = $scope.allFileno.indexOf($scope.restoreFn)
                $scope.allFileno.splice(index, 1);


                ngDialog.close();
                ngDialog.open({
                    template: '\
                            <p>Restored...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

                --$scope.totalFileno;

            }).
            error(function() {

                ngDialog.open({
                    template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

            });
        },

        restoreFileno: function(fn) {


            $scope.restoreFn = fn;
            ngDialog.openConfirm({
                template: '\
                                    <p>Are you sure you want to restore ?</p>\
                                    <div class="ngdialog-buttons">\
                                        <button type="button" class="ngdialog-button btn " ng-click="closeThisDialog()">No</button>\
                                        <button type="button" class="ngdialog-button btn btn-danger" ng-click="restore()">Yes</button>\
                                    </div>',

                plain: true,
                scope: $scope
            });

        },
        /*confirm if restore fileno */



        getDatas: function(pageNumber) {

            $scope.totalFileno = 0;

            if ($scope.searchTerm == '') {

                if ($scope.home) {
                    $scope.getData(pageNumber);
                }
                else {
                    $scope.getTrashData(pageNumber);
                }

            }




            if ($scope.searchTerm != '') {


                if ($scope.home) {
                    filenoModel.searchFileno($scope.searchTerm, pageNumber).success(function(response) {

                      
                $scope.lastPage = response.last_page;
                $scope.totalFileno = response.total;
                $scope.currentPage = response.current_page;

                // Pagination Range
                var pages = [];

                for (var i = 1; i <= response.last_page; i++) {
                    pages.push(i);
                }

                $scope.range = pages;


                $scope.allFileno = response.data;



                $scope.global.allFileno = response.data;
                $scope.global.totalFileno = response.total;
                $scope.global.filenoCurrentPage = response.current_page;
                $scope.global.filenoRange = pages;
                $scope.global.filenoLastPage = response.last_page;


                    });

                }
                else {
                    filenoModel.searchFilenoTrash($scope.searchTerm, pageNumber).success(function(response) {

                       
                $scope.lastPage = response.last_page;
                $scope.totalFileno = response.total;
                $scope.currentPage = response.current_page;

                // Pagination Range
                var pages = [];

                for (var i = 1; i <= response.last_page; i++) {
                    pages.push(i);
                }

                $scope.range = pages;


                $scope.allFileno = response.data;



                $scope.global.allFileno = response.data;
                $scope.global.totalFileno = response.total;
                $scope.global.filenoCurrentPage = response.current_page;
                $scope.global.filenoRange = pages;
                $scope.global.filenoLastPage = response.last_page;


                    });

                }
            }





        },



        
        
        
        
        
        printFileno: function() {
            var printContents = document.getElementById('filenoDetail').innerHTML;
            var popupWin = window.open('_blank', 'width=700,height=700');
            popupWin.document.open();
            popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="/bower_components/bootstrap/paper/paper.css" /><link rel="stylesheet" type="text/css" href="/css/app.css"  /></head><body onload="window.print()">' + printContents + '</body></html>');
            popupWin.document.close();
        }
    });

    if ($scope.global.allFileno == undefined) {
        $scope.getDatas(1);
    }
    else {
        $scope.allFileno = $scope.global.allFileno;



        $scope.totalFileno = $scope.global.totalFileno;
        $scope.currentPage = $scope.global.filenoCurrentPage;
        $scope.range = $scope.global.filenoRange;

        $scope.lastPage = $scope.global.filenoLastPage;

    }

var searchTermTimeout, tempSearchTerm;

    $scope.$watch('searchTerm', function(val) {

        $scope.global.fnSearchTerm = val;


        if (!$scope.searchFocused) {
            return;
        }

        if (searchTermTimeout) $timeout.cancel(searchTermTimeout);

        tempSearchTerm = val;

        searchTermTimeout = $timeout(function() {


            $scope.getDatas(1);



        }, 1000); // delay 250 ms


    })


}]);
