myApp.controller('navController', ['$scope', 'userModel', '$location', function($scope, userModel, $location) {

    angular.extend($scope, {
        // user: userModel.getUserObject(),
        navUrl: [{
            link: 'Home',
            url: '#/dashboard'
        }, {
            link: 'Coversheet',
            url: '#/dashboard/coversheet'
        }, {
            link: 'Invoice',
            url: '#/dashboard/invoice'
        }, {
            link: 'FileNo',
            url: '#/dashboard/fileno'
        }, ]
    });
    angular.extend($scope, {
        doLogout: function() {
            userModel.doUserLogout().success(function(ref) {


                $scope.global.allCoversheet = undefined;
                $scope.global.allInvoice = undefined;

                $scope.global.allFileno = undefined;
                $scope.global.allEmployee = undefined;
                $scope.global.isAdmin = undefined;
                
                $scope.global.csHome=undefined;
                   $scope.global.csSearchTerm=undefined;
                console.log( $scope.global);
                
                // window.cation('/');

                $location.url('/');
            });


        },
        checkActiveLink: function(routeLink) {
            if ('#' + $location.path() == routeLink) {
                return 'active';
            }

        }

    });


}]);
