myApp.controller('globalController', ['$scope', 'userModel','$location', function($scope, userModel,$location) {
   $scope.global = {};
    $scope.global.navUrl = 'templates/partials/nav.html';
   

 console.log('gb');

    if ($scope.global.user == undefined) {
        userModel.getUserObject().success(function(response) {
            
            
            //   $location.path('/dashboard');
           
            $scope.global.user = response;
        }).error(function(err) {
             console.log('no user logged in');
           $location.path('/');
        })
    }
    
     $scope.global.isAdmin = userModel.isAdmin();
    
    
    // $scope.global.isAdmin = userModel.isAdmin();
    // $scope.$on('updateUser', function (value) {
    //     $scope.global.user = userModel.getUserObject();
    // });
    // $scope.$on('updateRole', function(value) {
    //     $scope.global.isAdmin = userModel.isAdmin();
    // });

}]);
