myApp.controller('invoiceController', ['$scope', 'invoiceModel', '$location', '$timeout', 'ngDialog', '$filter', 'moment', function($scope, invoiceModel, $location, $timeout, ngDialog, $filter, moment) {

    if ($scope.global.invHome == undefined) {
        $scope.home = true;
    }
    else {
        $scope.home = $scope.global.invHome;
    }

    if ($scope.global.invSearchTerm == undefined) {
        $scope.searchTerm = '';
    }
    else {
        $scope.searchTerm = $scope.global.invSearchTerm;
    }

    if ($scope.global.defaultBank == undefined) {

        invoiceModel.getDefaultBank().success(function(response) {
            console.log(response);

            $scope.defaultBank = response;
            $scope.global.defaultBank = response;

        });

    }
    else {
        $scope.defaultBank = $scope.global.defaultBank;
    }





    /*variable*/
    angular.extend($scope, {
      

        searchFocused: false,
        readonly: true,
        errorDiv: false,
        errorMessage: [],
        detailInvoice: {},

        editInvoice: {
            fees: 0,
            travel: 0,
            photos: 0,
            expenses: 0,
            total: 0,
        },
        newInvoice: {},

        modelInsert: false,
        modelDetail: false,
        modelEdit: false,
        isSaving: false,

        lastPage: 0,
        currentPage: 1,
        range: [],
        totalInvoice: 0,



    });


    /*function*/
    angular.extend($scope, {


        setDefaultBank: function(dt) {


            if (dt) {


                $scope.newInvoice.bankName = $scope.defaultBank.bankName;
                $scope.newInvoice.bankAddress = $scope.defaultBank.bankAddress;
                $scope.newInvoice.bankAcName = $scope.defaultBank.bankAcName;
                $scope.newInvoice.bankAcNumber = $scope.defaultBank.bankAcNumber;
                $scope.newInvoice.bankAbaNumber = $scope.defaultBank.bankAbaNumber;
                $scope.newInvoice.bankSwiftCode = $scope.defaultBank.bankSwiftCode;

            }
            else {
                $scope.newInvoice.bankName = "";
                $scope.newInvoice.bankAddress = "";
                $scope.newInvoice.bankAcName = "";
                $scope.newInvoice.bankAcNumber = "";
                $scope.newInvoice.bankAbaNumber = "";
                $scope.newInvoice.bankSwiftCode = "";
            }
        },



        getData: function(pageNumber) {


            invoiceModel.getInvoice(pageNumber).success(function(response) {


                $scope.lastPage = response.last_page;
                $scope.totalInvoice = response.total;
                $scope.currentPage = response.current_page;

                // Pagination Range
                var pages = [];

                for (var i = 1; i <= response.last_page; i++) {
                    pages.push(i);
                }

                $scope.range = pages;


                $scope.allInvoice = response.data;



                $scope.global.allInvoice = response.data;
                $scope.global.totalInvoice = response.total;
                $scope.global.invoiceCurrentPage = response.current_page;
                $scope.global.invoiceRange = pages;
                $scope.global.invoiceLastPage = response.last_page;




            });


        },



        viewHome: function() {
            $scope.searchTerm = '';
            $scope.home = true;
            $scope.global.invHome = true;

            $scope.getDatas(1);

        },
        getTrashData: function(pageNumber) {
            invoiceModel.getInvoiceTrash(pageNumber).success(function(response) {


                $scope.lastPage = response.last_page;
                $scope.totalInvoice = response.total;
                $scope.currentPage = response.current_page;

                // Pagination Range
                var pages = [];

                for (var i = 1; i <= response.last_page; i++) {
                    pages.push(i);
                }

                $scope.range = pages;


                $scope.allInvoice = response.data;



                $scope.global.allInvoice = response.data;
                $scope.global.totalInvoice = response.total;
                $scope.global.invoiceCurrentPage = response.current_page;
                $scope.global.invoiceRange = pages;
                $scope.global.invoiceLastPage = response.last_page;



            });

        },

        viewTrash: function() {
            $scope.searchTerm = '';
            $scope.home = false;
            $scope.global.invHome = false;
            $scope.getTrashData(1);

        },




        msToTime: function(duration) {
            var milliseconds = parseInt((duration % 1000) / 100),
                seconds = parseInt((duration / 1000) % 60),
                minutes = parseInt((duration / (1000 * 60)) % 60),
                hours = parseInt((duration / (1000 * 60 * 60)) % 24);

            hours = (hours < 10) ? "0" + hours : hours;
            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + seconds : seconds;

            return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
        },
        timeToString: function(time) {
            var k = time.split(':');
            return {
                hours: k[0],
                minutes: k[1],
                seconds: k[2]
            };
        },




        /*model insert show/hide*/
        modelInsertShow: function() {
            $scope.modelInsert = true;


        },
        modelInsertClose: function() {
            $scope.isSaving = false;
            $scope.modelInsert = false;
            $scope.newInvoice = {};
        },

        /*model detail show/hide*/
        modelDetailShow: function(inv) {
            $scope.detailInvoice = inv;
            console.log(inv);
            $scope.modelDetail = true;
        },
        modelDetailClose: function() {
            $scope.modelDetail = false;
        },

        /*model edit show/hide*/
        modelEditShow: function(inv) {

            $scope.edt = inv;
            var invData = angular.copy(inv);


            if (invData.date !== null) {

                invData.date = new Date(invData.date);

            }




            $scope.editInvoice = invData;


            $scope.modelEdit = true;
        },
        modelEditClose: function() {
            $scope.modelEdit = false;

        },

        /*insert new Invoice*/
        insertInvoice: function(invoiceForm) {



            $scope.newInvoice.total = $scope.newInvoice.fees + $scope.newInvoice.travel + $scope.newInvoice.photos + $scope.newInvoice.expenses;

            $scope.isSaving = true;

            if (invoiceForm.$valid) {
                $scope.invoiceFormSubmitted = false;

                invoiceModel.insertInvoice($scope.newInvoice)
                    .success(function(response) {
                        $scope.newInvoice = {};
                        $scope.isSaving = false;
                        $scope.allInvoice.push(response);

                        ngDialog.open({
                            template: '\
                            <p>Inserted successfully...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                            plain: true,
                            className: 'ngdialog-theme-default'
                        });
                        ++$scope.totalInvoice;


                    })
                    .error(function(err) {
                        $scope.isSaving = false;
                        console.log(err);
                        ngDialog.open({
                            template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                            plain: true,
                            className: 'ngdialog-theme-default'
                        });



                        $location.path('/');
                    })

            }
            else {
                $scope.invoiceFormSubmitted = true;
                console.log('invalid form');
                $scope.isSaving = false;
            }

        },

        /*edit invoice*/
        EditInvoice: function(invoiceEditForm) {


            $scope.editInvoice.total = $scope.editInvoice.fees +
                $scope.editInvoice.travel +
                $scope.editInvoice.photos +
                $scope.editInvoice.expenses;

            $scope.editIsSaving = true;

            if (invoiceEditForm.$valid) {
                $scope.invoiceEditFormSubmitted = false;
                invoiceModel.editInvoice($scope.editInvoice)
                    .success(function(response) {

                        console.log(response);
                        console.log($scope.editInvoice);
                        var index = $scope.allInvoice.indexOf($scope.edt)

                        $scope.allInvoice[index] = $scope.editInvoice;


                        $scope.editIsSaving = false;




                        ngDialog.open({
                            template: '\
                            <p>Edited successfully...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                            plain: true,
                            className: 'ngdialog-theme-default'
                        });
                        $scope.modelEditClose();
                        $scope.editInvoice = {};


                    })
                    .error(function(err) {


                        $scope.editIsSaving = false;
                        console.log(err);
                        ngDialog.open({
                            template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                            plain: true,
                            className: 'ngdialog-theme-default'
                        });
                        $location.path('/');
                    })

            }
            else {
                $scope.invoiceEditFormSubmitted = true;
                console.log('invalid form');
                $scope.editIsSaving = false;
            }

        },

        /*delete Invoice */
        delete: function() {


            invoiceModel.deleteInvoice($scope.delInv.id).success(function(res) {


                var index = $scope.allInvoice.indexOf($scope.delInv)
                $scope.allInvoice.splice(index, 1);



                ngDialog.close();
                ngDialog.open({
                    template: '\
                            <p>Deleted...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

                --$scope.totalInvoice;

            }).
            error(function() {

                ngDialog.open({
                    template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

            });
        },

        deleteInvoice: function(inv) {


            $scope.delInv = inv;
            ngDialog.openConfirm({
                template: '\
                                    <p>Are you sure you want to delete ?</p>\
                                    <div class="ngdialog-buttons">\
                                        <button type="button" class="ngdialog-button btn " ng-click="closeThisDialog()">No</button>\
                                        <button type="button" class="ngdialog-button btn btn-danger" ng-click="delete()">Yes</button>\
                                    </div>',

                plain: true,
                scope: $scope
            });

        },
        /*confirm if delete invoice */



        /*restore Invoice */
        restore: function() {


            invoiceModel.restoreInvoice($scope.restoreInv.id).
            success(function(res) {

                var index = $scope.allInvoice.indexOf($scope.restoreInv)
                $scope.allInvoice.splice(index, 1);


                ngDialog.close();
                ngDialog.open({
                    template: '\
                            <p>Restored...</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-primary" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

                --$scope.totalInvoice;

            }).
            error(function() {

                ngDialog.open({
                    template: '\
                            <p>Something went wrong ! please try again</p>\
                            <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button btn-danger" ng-click="closeThisDialog()">OK</button>\
                             </div>',
                    plain: true,
                    className: 'ngdialog-theme-default'
                });

            });
        },

        restoreInvoice: function(inv) {


            $scope.restoreInv = inv;
            ngDialog.openConfirm({
                template: '\
                                    <p>Are you sure you want to restore ?</p>\
                                    <div class="ngdialog-buttons">\
                                        <button type="button" class="ngdialog-button btn " ng-click="closeThisDialog()">No</button>\
                                        <button type="button" class="ngdialog-button btn btn-danger" ng-click="restore()">Yes</button>\
                                    </div>',

                plain: true,
                scope: $scope
            });

        },
        /*confirm if restore invoice */



        getDatas: function(pageNumber) {

            $scope.totalInvoice = 0;

            if ($scope.searchTerm == '') {

                if ($scope.home) {
                    $scope.getData(pageNumber);
                }
                else {
                    $scope.getTrashData(pageNumber);
                }

            }




            if ($scope.searchTerm != '') {


                if ($scope.home) {
                    invoiceModel.searchInvoice($scope.searchTerm, pageNumber).success(function(response) {

                        $scope.lastPage = response.last_page;
                        $scope.totalInvoice = response.total;
                        $scope.currentPage = response.current_page;

                        // Pagination Range
                        var pages = [];

                        for (var i = 1; i <= response.last_page; i++) {
                            pages.push(i);
                        }

                        $scope.range = pages;


                        $scope.allInvoice = response.data;



                        $scope.global.allInvoice = response.data;
                        $scope.global.totalInvoice = response.total;
                        $scope.global.invoiceCurrentPage = response.current_page;
                        $scope.global.invoiceRange = pages;
                        $scope.global.invoiceLastPage = response.last_page;


                    });

                }
                else {
                    invoiceModel.searchInvoiceTrash($scope.searchTerm, pageNumber).success(function(response) {

                        $scope.lastPage = response.last_page;
                        $scope.totalInvoice = response.total;
                        $scope.currentPage = response.current_page;

                        // Pagination Range
                        var pages = [];

                        for (var i = 1; i <= response.last_page; i++) {
                            pages.push(i);
                        }

                        $scope.range = pages;


                        $scope.allInvoice = response.data;



                        $scope.global.allInvoice = response.data;
                        $scope.global.totalInvoice = response.total;
                        $scope.global.invoiceCurrentPage = response.current_page;
                        $scope.global.invoiceRange = pages;
                        $scope.global.invoiceLastPage = response.last_page;

                    });

                }
            }





        },



        printInvoice: function() {

            var printContents = document.getElementById('invoiceDetail').innerHTML;
            var popupWin = window.open('_blank', 'width=700,height=700');
            popupWin.document.open();
            popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="/bower_components/bootstrap/paper/paper.css" /><link rel="stylesheet" type="text/css" href="/css/app.css"  /><title>Invoice:' + $scope.detailInvoice.id + '</title></head><body onload="window.print()">' + printContents + '</body></html>');
            popupWin.document.close();


        }
    });


    if ($scope.global.allInvoice == undefined) {
        $scope.getDatas(1);
    }
    else {
        $scope.allInvoice = $scope.global.allInvoice;



        $scope.totalInvoice = $scope.global.totalInvoice;
        $scope.currentPage = $scope.global.invoiceCurrentPage;
        $scope.range = $scope.global.invoiceRange;

        $scope.lastPage = $scope.global.invoiceLastPage;

    }

    var searchTermTimeout, tempSearchTerm;

    $scope.$watch('searchTerm', function(val) {

        $scope.global.invSearchTerm = val;


        if (!$scope.searchFocused) {
            return;
        }

        if (searchTermTimeout) $timeout.cancel(searchTermTimeout);

        tempSearchTerm = val;

        searchTermTimeout = $timeout(function() {


            $scope.getDatas(1);



        }, 1000); // delay 250 ms


    })

}]);
